<template>
  <div v-click-outside="selectFirstCity">
    <v-autocomplete
      v-model="city"
      :items="cities"
      :search-input.sync="searchInputCity"
      return-object
      :rules="cityRules"
      :label="label"
      required
      ref="input_from"
      @keydown.enter="selectFirstCity"
      @keydown.tab="selectFirstCity"
    >
    </v-autocomplete>
  </div>
</template>

<script>
import {topCities} from "../../services/constants";

export default {
  name: "InputTown",

  props: {
    IsRailway: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      required: true
    },
    start: {
      value:{
        type:String,
        required:false,
        default:''
      }
    },
    label: {
      value:{
        type:String,
        required: false,
        default:''
      }
    },
    endpoint: {
      type: String,
      default: "/Data/Stations/Get"
    },
  },
  model: {
    prop: ["value"],
    event: ["change-value"],
  },
  emits: ['change-value'],

  data() {
    return {
      city: null,
      searchInputCity: null,
      arrayCities: [],
      cityRules: [(v) => !!v || "Выберите город"],
      timerFilter: null,

    };
  },
  async mounted() {
    if (this.start === '') return

    let city = await this.getCityByCode(this.start);
    this.cities = [
      {
        text: `${city.text}`,
        value: {
          code: `${city.value.code}`,
          name: `${city.value.name}`,
        },
      },
    ];
    this.city = {
      text: `${city.text}`,
      value: {
        code: `${city.value.code}`,
        name: `${city.value.name}`,
      },
    };
  },

  computed: {
    cities: {
      get() {
        return this.sortCitiesArray(this.arrayCities);
      },
      set(newValue) {
        this.arrayCities = newValue;
      },
    },
  },

  watch: {
    city(val) {
      this.$emit("change-value", val);
    },
    searchInputCity: {
      async handler() {
        clearTimeout(this.timerFilter);
        this.timerFilter = setTimeout(async () => {
          this.cities = await this.getStations(this.searchInputCity);
        }, 800);
      },
    },

    value: {
      handler(newItem) {
        this.cities = [newItem]
        this.city = newItem
      },
      deep: true,
    },
  },

  methods: {
    sortCitiesArray(array) {
      return array.sort((a, b) => {
        let indexA = topCities.indexOf(a.text);
        let indexB = topCities.indexOf(b.text);
        
        if (indexA !== -1 && indexB !== -1) return indexA - indexB;
        if (indexA !== -1) return -1;
        if (indexB !== -1) return 1;
        
        return a.text.localeCompare(b.text);
      });
    },

    selectFirstCity() {
      if (this.cities.length > 0) {
        this.city = this.cities[0];
        this.$emit("change-value", this.city);
      }
    },

    async getStations(val) {
      if (val === null || val === "") return [];
      let stations = [];
      let url = this.endpoint;
      if (val.length >= 2) {
        let resp = await this.$axios.get(url + `?text=${val}`);

        if (this.IsRailway) {
          resp.data.forEach((e) => {
            stations.push({
              text: e,
              value: {
                code: e,
                name: e,
              },
            });
          });
          return stations;
        } else {
          resp.data.forEach((e) => {
            stations.push({
              text: e.nameRu,
              value: {
                code: e.codeEn,
                name: e.nameRu,
              },
            });
          });
          return stations
        }
      }
      return stations;
    },

    async getCityByCode(val) {
      let station;
      let result = await this.$axios.get(
          this.$enums.Endpoints.Data.Station + `?text=${val}`
      );
      station = {
        text: result.data.nameRu.replace(/\(.+\)/, ''),
        value: {
          code: result.data.codeEn,
          name: result.data.nameRu.replace(/\(.+\)/, ''),
        },
      }
      return station;
    },
  },
}
</script>

<style>
.v-autocomplete input {
  font-size: 1.2rem;
}
</style>
